.app {
    font-family: "Montserrat", sans-serif;
    font-size: 100%;
    line-height: 1.5;
    color: #404354;
    display: flex;
    flex-flow: column;
    align-items: center;
    text-align: center;
  }
  
  .container {
    width: 100%;
    
  }
  
  .container img {
    max-width: 100%;
    height: 100%;
  }