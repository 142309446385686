@import "~bootstrap/scss/bootstrap";
@import "./../../basedata/variables";

.qr-code {
  width: 100%;
  max-width: 300px;
  margin: 24px auto; }

.qr-link {
  text-align: center;
  color: $pm-primary; }
