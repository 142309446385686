@import "~bootstrap/scss/bootstrap";
@import "./basedata/variables";

body {
  min-height: 100vh;
  // background: #ffffff !important
  background: linear-gradient(0deg, rgb(144, 144, 144) 0%, rgb(255, 255, 255) 100%) no-repeat; }

.Container {
  min-height: 95vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .logo {
    max-width: 200px; }

  .logos {
    text-align: center;
    margin: 2em 0; }

  .logo-bet {
    display: block;
    width: 45%;
    margin: 0 auto; } }

.card {
  padding: 24px;
  background-color: #fff;
  width: 80%;
  max-width: 400px;
  border-radius: 8px;
  transition: all ease 2s;

  h1 {
    width: 100%;
    @include fontDefault;
    font-size: 2em;
    color: $pm-dark;

    span {
      @include fontSuper; } }

  p {
    @include fontDefault;
    font-size: 1em;
    line-height: 1.3em;
    color: $gray-600;

    &.loading {
      min-height: 300px;
      text-align: center;
      background-color: #2c661f;
      color: #ffffff;
      justify-content: center;

      h1 {
        display: none; } }

    .form-group {
      padding: 8px;

      .primary {
        background-color: $pm-dark;
        color: #ffffff; } }
    .text-muted {
      color: #cb6a6a !important; } } }

.form-wrapper {
  padding-top: 24px;

  .form-floating {
    margin: 8px 0;
    label {
      @include fontDefault;
      padding-bottom: 4px;
      color: $gray-600; }

    input {
      border-radius: 4px;
      @include fontDefault;
      font-size: 1em;
      color: $gray-700; } } }

.form-submit {
  border-top: 1px solid tint-color($pm-primary, 80%);
  padding-top: 24px;
  margin-top: 24px;

  .btn-primary {
    background-color: $pm-dark !important;
    @include fontTitle;
    color: $white; } }

.help-link {
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  padding: 8px 0;
  @include fontDefault;
  font-size: 0.8em;
  text-decoration: none;
  color: $pm-primary; }

.form-check {
  display: flex;
  align-items: center; }

.form-check-label {
  text-transform: uppercase;
  padding: 8px 0;
  @include fontDefault;
  font-size: 0.8em;
  line-height: 1.2em;
  text-decoration: none;
  color: $gray-600;
  margin-left: 16px;

  a {
    @include fontTitle;
    color: $pm-dark; } }

.label-error {
  @include fontDefault;
  font-size: 0.8em;
  color: $red-400; }

.step-counter {
  width: 100%;
  text-align: center;
  margin-top: 8px;
  padding: 0;

  li {
    display: inline-block;
    margin: 0 8px;
    border-radius: 50%;
    border: 1px solid $white;
    padding: 4px;

    &.active {
      background-color: tint-color($pm-primary, 80%);
      border-color: tint-color($pm-primary, 80%); } } }

.copyright {
  text-align: center;
  @include fontDefault;
  font-size: 0.8em;
  color: $gray-600;

  a {
    text-decoration: none;
    color: $white;
    vertical-align: middle;
    &:hover {
      color: $pm-primary; } }

  .logo-bepass {
    max-width: 90px;
    margin-left: 6px; } }
